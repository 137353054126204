    $(function() {
      const _ww = $(window).outerWidth(true) + 17
      const _wh = $(window).outerHeight(true)
      let _nav_offst_top = Math.floor($('.navigation').offset().top)
      let $top = $('.top')
      //console.log('_nav_offst_top:', _nav_offst_top)
      var _switch = true;
      $(window).scroll(function() {
        const _st = $(window).scrollTop();
        if (_ww >= 1280) {
          if ((_st - $('.navigation').outerHeight(true) >= _nav_offst_top) && _switch) {
            _switch = false;
            // console.log('超過第一cut', _switch)
            $('.container').css({
              position: 'static',
              alignItems: 'center'
            })
            $('.top').css({
              position: 'fixed',
              // opacity: 0,
              top: -120
            }).animate({
              // opacity: 1,
              top: 0
            })

            $('.navigation').addClass('add').css({
              position: 'static',
              backgroundColor: 'transparent'
            })

            $('.menu').css({
              width: '1086px'
            })
            $('.menu').find('li>a').css({
              padding: '18px 40px 17px'
            })

          } else if (_st - $('.navigation').outerHeight(true) < _nav_offst_top && !_switch) {
            _switch = true;
            // console.log('未超過第一cut', _switch)
            $('.container').css({
              position: 'absolute',
              alignItems: 'flex-start'
            })

            $('.top').css({
              position: 'static',
              top: 0
            })

            $('.navigation').css({
              backgroundColor: "rgba(0, 0, 0, .8)",
              position: 'absolute',
              bottom: 0,
            })

            $('.menu').css({
              width: '1066px'
            })
            $('.menu').find('li>a').css({
              padding: '40px 40px 40px'
            })
          }
        } else if (_ww >= 960 && _ww < 1280) {
          if ((_st - $('.navigation').outerHeight(true) >= _nav_offst_top) && _switch) {
            _switch = false;
            // console.log('超過第一cut', _switch)
            $('.container').css({
              position: 'static',
              alignItems: 'center'
            })

            $('.top').css({
              position: 'fixed',
              top: -120
            }).animate({
              top: 0
            })

            $('.navigation').addClass('add').css({
              position: 'static',
              backgroundColor: 'transparent'
            })

            $('.menu').css({
              width: '742px'
            })

          } else if (_st - $('.navigation').outerHeight(true) < _nav_offst_top && !_switch) {
            _switch = true;
            // console.log('未超過第一cut', _switch)
            $('.container').css({
              position: 'absolute',
              alignItems: 'flex-start'
            })

            $('.top').css({
              position: 'static',
              top: 0
            })

            $('.navigation').css({
              backgroundColor: "rgba(0, 0, 0, .8)",
              position: 'absolute',
              bottom: 0,
            })

            $('.menu').css({
              width: '722px'
            })

          }
        } else if (_ww >= 767 && _ww < 960) {
          if ((_st - $('.navigation').outerHeight(true) >= _nav_offst_top) && _switch) {
            _switch = false;
            // console.log('超過第一cut', _switch)
            $('.container').css({
              position: 'static',
              alignItems: 'center'
            })

            $('.top').css({
              position: 'fixed',
              top: -120
            }).animate({
              top: 0
            })

            $('.navigation').addClass('add').css({
              position: 'static',
              backgroundColor: 'transparent'
            })

            $('.menu').css({
              //marginRight: '20px',
              width: '610px'
            })

          } else if (_st - $('.navigation').outerHeight(true) < _nav_offst_top && !_switch) {
            _switch = true;
            // console.log('未超過第一cut', _switch)
            $('.container').css({
              position: 'absolute',
              alignItems: 'flex-start'
            })

            $('.top').css({
              position: 'static',
              top: 0
            })

            $('.navigation').css({
              backgroundColor: "rgba(0, 0, 0, .8)",
              position: 'absolute',
              bottom: 0,
            })

            $('.menu').css({
              width: '590px'
            })

          }
        }

      })
    })